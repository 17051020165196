$content-max-width: 730px;

// Colors

$muted-pink: #D98F9D;
$pink: #F299C2;
$green: #4a6c32;
$light-green: #D8D9CC;
$olive: #A6A380;

$dark-green: #4a6c32;
$dark-pink: #e976ab;

.spacer-vertical-40 {
    height: 60px;
}

body {
    margin: 0;
    font: 100%/1.625 -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 100%;
    line-height: 1.625;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    background-color: $dark-green;
    
    h1, h2, h3, h4, h5 {
        font-weight: 600;
        line-height: 1.2;
        color: $dark-green;

        a {
            color: $dark-green;
        }
    }

    a {
        text-decoration: none;
        color: $dark-pink;
    }

    a:hover {
        text-decoration: underline;
    }

    .content {
        background-color: white;

        .content-centered {
            padding-top: 60px;
            margin-left: auto;
            margin-right: auto;
            max-width: $content-max-width;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
        }
    }
}

.main-nav {
    // height: 102px;
    background-color: $dark-green;

    .main-nav-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 100px;
        padding-left: 30px;
        max-width: 1050px;
        margin-left: auto;
        margin-right: auto;
    }

    .logo {
        font-size: 2.8rem;
        text-decoration: none;
        color: white;
        // margin-top: 12px;
        font-weight: 100;
        margin-right: 10px;
        padding-left: 10px;

        // img {
        //     border-radius: 10px;
        // }
    }

    .links {
        display: flex;
        // flex-wrap: wrap-reverse;
        // flex-direction: row-reverse;
        // align-items: center;
        // justify-content: flex-start;
        // flex-grow: 1;
        // overflow: hidden;
        // height: 100px;

        a {
            color: white;
            // height: 100px;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 1.1rem;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 30px;

            @media (max-width: 760px) {
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    
        .current {
            text-decoration: underline;
        }
    }

    .expand-links-container {
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .expand-links {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }

    .expand-links:hover {
        background: #ffffff11;
    }
}

.sub-nav {
    overflow: hidden;

    &.opened, &.closed {
        max-height: 0px;
        transition: max-height 0.3s ease-in-out;
    }

    &.opened {
        max-height: 200px;
    }

    .links {
        display: flex;
        align-items: flex-end;
        flex-direction: column-reverse;
        padding-right: 30px;
        padding-bottom: 20px;
        font-size: 1.3rem;
    }

    a {
        color: white;
    }
}

.pagination {
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .page_number {
        padding-left: 10px;
        padding-right: 10px;
    }

    .next, .previous {
        min-width: 70px;
        padding-top: 8px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 0.7rem;
    }

    a.next, a.previous {
        background-color: $dark-green;
        color: white;
    }

    span.next, span.previous {
        background-color: #dcdfd9;
        color: #555a51;
    }
}

.post-title {
    // margin-top: 0;
    margin-bottom: 3px;
    text-align: center;
}

.post-meta {
    font-size: .8em;
    margin-bottom: 30px;
    margin-top: 0;
    text-align: center;
    color: #777;
}

.post-player {
    background-color: #ddd;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
        margin-right: 20px;
        max-width: 100%;
        width: 90px;
        height: 90px;
        @media (max-width: 400px) {
            margin-right: 10px;
            width: 50px;
            height: 50px;
        }
    }

    audio {
        flex-grow: 1;
        margin: 0 10px;
    }
}

.post-content {
    border-bottom: 1px #ccc solid;
    padding-bottom: 40px;
    margin-bottom: 80px;

    img {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        max-height: 500px;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 15px 0;
    background-color: $dark-green;
    color: #FFFFFF77;

    .links {
        align-content: center;
        justify-content: center;
        display: flex;
        align-items: center;

        a {
            color: white;
            padding: 0 10px;
        }
    }

    p {
        font-size: 0.7rem;
        text-align: center;
    }
}

.page-not-found {
    text-align: center;
    padding-bottom: 20px;
}