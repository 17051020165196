.footnotes {
    font-size: .8rem;
    color: #777;
}

.footnotes li p {
    display: inline;
}

blockquote.twitter-tweet {
    display: inline-block;
    font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
    margin: 10px 0;
    margin-bottom: 40px;
    padding: 16px;
    max-width: 468px;
  }
  
  blockquote.twitter-tweet p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }
  
  blockquote.twitter-tweet a {
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    outline: 0 none;
  }
  
  blockquote.twitter-tweet a:hover,
  blockquote.twitter-tweet a:focus {
    text-decoration: underline;
    border-bottom: none;
  }

.postCaption {
  text-align: center;
  font-size: .75em;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 30px;
}

.postVideoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.postVideo {
  max-height: 400px;
  max-width: 100%;
}

.postYoutube {
  position: relative;
  padding-bottom: 56%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 30px;
}

.postYoutube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}